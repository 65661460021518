//
// HFL less
// --------------------------------------------------
// Styles directory
// 1. Global
// 1.1 Fonts
// 1.2 Colors
// 1.3 Elements
// 2. Frame
// 3. Navigation
// 4. Section 01 Whatissugaring
// --------------------------------------------------

// 1. Global
// 1.1 Fonts
// use font-family: 'Love Ya Like A Sister', cursive;
// use font-family: 'Open Sans', sans-serif;

// 1.2 Colors
@color_menuitemtext: #ffffff; 
@color_menuitemtext_hover: #7d796e; 
// 1.3 Elements
@navi_height_xs: 50px;
@navi_height_sm: 83px;
@navi_height_md: 83px;
@navi_height_lg: 83px;
body  {
	margin-top: @navi_height_xs;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	// Small grid
	@media (min-width: @screen-sm-min) {
		margin-top: @navi_height_lg;
	}
	// Medium grid
	@media (min-width: @screen-md-min) {
		margin-top: @navi_height_lg;
	}
	// Large grid
	@media (min-width: @screen-lg-min) {
		margin-top: @navi_height_lg;
	}	
}
section {
	padding-top: @navi_height_xs;
	margin-top: -@navi_height_xs;
	// Small grid
	@media (min-width: @screen-sm-min) {
		padding-top: @navi_height_lg;
		margin-top: -@navi_height_lg;
	}
	// Medium grid
	@media (min-width: @screen-md-min) {
		padding-top: @navi_height_lg;
		margin-top: -@navi_height_lg;
	}
	// Large grid
	@media (min-width: @screen-lg-min) {
		padding-top: @navi_height_lg;
		margin-top: -@navi_height_lg;
	}	
}
h1,h2,h3,h4 {
	font-family: 'Love Ya Like A Sister', cursive;
}
// 2. Frame

// 3. Navigation
.navbar-default {
	background-color: #94b54d;
	border-color: #94b54d;
	font-family: 'Love Ya Like A Sister', cursive;
	.navbar-nav {
		li { 
			a {
				font-size: 18px;
				color: @color_menuitemtext;
				// Small grid
				@media (min-width: @screen-sm-min) {
					line-height: 200%;
					font-size: 2.2vw;
					padding-left: 1.5vw;
					padding-right: 1.5vw;
				}
				&:hover, &:focus {
					color: @color_menuitemtext_hover;
				}
			}	
			&:last-child {
				a {
					// Small grid
					@media (min-width: @screen-sm-min) {
						margin-left: 2vw;
					}
					// Medium grid
					@media (min-width: @screen-md-min) {
						
					}	
					// Large grid
					@media (min-width: @screen-lg-min) {
						margin-left: 3vw;
					}
				}
				&.active {
					a {
						background-color: #94b54d !important;
						color: @color_menuitemtext;						
					}
				}				
			}
			&.active {
				a {
					background-color: #acc779;
				}
			}
		}
	}
	.navbar-brand {
		padding-top: 5px;
		// Small grid
		@media (min-width: @screen-sm-min) {
			margin-right: 2vw;
			padding-top: 15px;
		}	
		// Medium grid
		@media (min-width: @screen-md-min) {

		}	
		// Large grid
		@media (min-width: @screen-lg-min) {
			margin-right: 4vw
		}		
		img {
			height: 42px;
			// Small grid
			@media (min-width: @screen-sm-min) {
				height: 4.3vw;
			}
		}
	}
}

// 4. section whatissugaring
#whatissugaring {
	.hfl_intro {
		background-image: url("../assets/gfx/bg_whatissugaring_intro.jpg");
		background-repeat: no-repeat;
		background-position: center bottom;		
		padding-bottom: 64vw;
		background-size: 97vw;
		// Small grid
		@media (min-width: @screen-sm-min) {
			
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			padding-bottom: 0;
			background-position: left bottom;
			background-size: 53vw;	
			height: 36vw;
		}	
		// Large grid
		@media (min-width: @screen-lg-min) {
			
		}	
		h1 {
			color: #94b54d;
			padding-top: 7vw;
			text-align: center;
			font-size: 8vw;
			// Small grid
			@media (min-width: @screen-sm-min) {
				
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				font-size: 3.2vw;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
			img {
				width: 90%;
				// Small grid
				@media (min-width: @screen-sm-min) {
					
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					width: 50%;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {

				}					
			}
			span {
				color: #888179;
				text-transform: uppercase;
			}
		}
	}
	.hfl_intro2 {
		background-image: url("../assets/gfx/bg_whatissugaring_intro2.jpg");
		background-repeat: no-repeat;
		background-position: center bottom;		
		padding-bottom: 64vw;
		background-size: 97vw;
		// Small grid
		@media (min-width: @screen-sm-min) {
			
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			padding-bottom: 0;
			background-position: right bottom;
			background-size: 53vw;	
			height: 36vw;
		}	
		// Large grid
		@media (min-width: @screen-lg-min) {
			
		}	
		h1 {
			color: #94b54d;
			padding-top: 7vw;
			text-align: center;
			font-size: 8vw;
			// Small grid
			@media (min-width: @screen-sm-min) {
				
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				font-size: 3.2vw;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
			img {
				width: 90%;
				// Small grid
				@media (min-width: @screen-sm-min) {
					
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					width: 70%;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {

				}					
			}
			span {
				color: #888179;
				text-transform: uppercase;
			}
		}
	}	
	.hfl_explanations {
		& > div {
			display: inline-block;
			// Small grid
			@media (min-width: @screen-sm-min) {
				
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				height: 50vw;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}	
		}
		h3 {
			color: #ffffff;
			text-align: center;
			width: 100%;
			background-repeat: no-repeat;
			background-position: top center;	
			padding-top: 90px;
			background-size: 75px;
			margin-bottom: 3vw;			
			// Small grid
			@media (min-width: @screen-sm-min) {

			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				font-size: 2.2vw;
				margin-bottom: 3vw;
				background-size: 7vw;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
		}
		p {
			color: #ffffff;
			text-align: center;
			width: 100%;
			margin-bottom: 50px;
			padding-left: 20px;
			padding-right: 20px;			
			// Small grid
			@media (min-width: @screen-sm-min) {
				padding-left: 15vw;
				padding-right: 15vw;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				font-size: 1.2vw;
				padding-left: 5vw;
				padding-right: 5vw;
				text-align: center;
				margin-bottom: 0;			
			}
			// Large grid
			@media (min-width: @screen-lg-min) {
				font-size: 1.1vw;
				padding-left: 8vw;
				padding-right: 8vw;
			}			
		}
		.hfl_explanation01 {
			background-color: #94b54d;
			h3 {
				background-image: url("../assets/gfx/hfl-gegen-ziepen.png");				
				// Small grid
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					margin-top: 3.5vw;
					padding-top: 9vw;					
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					margin-top: 5vw;
				}
			}
		}
		.hfl_explanation02 {
			background-color: #acc779;
			h3 {
				background-image: url("../assets/gfx/hfl-suesses-geheimnis.png");				
				// Small grid
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					margin-top: 1.6vw;
					padding-top: 8vw;					
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					margin-top: 3vw;
				}
			}			
		}
		.hfl_explanation03 {
			background-color: #94b54d;
			// Small grid
			@media (min-width: @screen-sm-min) {
				
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				background-color: #acc779;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
			h3 {
				background-image: url("../assets/gfx/hfl-ganz-was-neues.png");				
				// Small grid
				@media (min-width: @screen-sm-min) {

				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					margin-top: 5vw;
					padding-top: 8vw;					
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					margin-top: 6vw;
				}
			}			
		}
		.hfl_explanation04 {
			background-color: #acc779;
			// Small grid
			@media (min-width: @screen-sm-min) {
				
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				background-color: #94b54d;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}				
			h3 {
				background-image: url("../assets/gfx/hfl-natuerliche-inhaltsstoffe.png");				
				// Small grid
				@media (min-width: @screen-sm-min) {
					
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					margin-top: 7vw;
					padding-top: 10vw;				
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					margin-top: 8vw;
				}
			}			
		}			
	}
}
// 5. Section Q&A
#qandas {
	padding-bottom: 50px;
	h2 {
		width: 100%;
		text-align: center;
		color: #94b54d;
		margin-top: 50px;
		background-image: url("../assets/gfx/hfl_qanda_underline.png");
		background-repeat: no-repeat;
		background-position: bottom center;	
		padding-bottom: 25px;
		background-size: 300px;
		margin-bottom: 30px;		
		// Small grid
		@media (min-width: @screen-sm-min) {

		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			font-size: 3.2vw;
			background-size: 31vw;
			margin-bottom: 2vw;	
			padding-bottom: 2vw;			
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
	}
	h3 {
		width: 100%;
		text-align: left;
		color: #94b54d;
	}
	p {
		span {
			font-weight: bold;
			color: #94b54d;
		}
		color: #62645e;
	}
}
// 6. Section Prices
#prices {
	.slideShow {
		padding-left: 0;
		padding-right: 0;
		height: 80vw;
		// Small grid
		@media (min-width: @screen-sm-min) {

		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			height: 50vw;
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
		.carousel {
			.carousel-inner {
				.item {
					height: 80vw;
					background-position: center;
					background-repeat: none;
					background-size: cover;
					// Small grid
					@media (min-width: @screen-sm-min) {

					}
					// Medium grid
					@media (min-width: @screen-md-min) {
						height: 50vw;
					}
					// Large grid
					@media (min-width: @screen-lg-min) {

					}					
				}
				.item0 {
					background-image: url("../assets/gfx/impressions/hfl_01.jpg");
				}
				.item1 {
					background-image: url("../assets/gfx/impressions/hfl_02.jpg");
				}
				.item2 {
					background-image: url("../assets/gfx/impressions/hfl_03.jpg");
				}
				.item3 {
					background-image: url("../assets/gfx/impressions/hfl_04.jpg");
				}	
				.item4 {
					background-image: url("../assets/gfx/impressions/hfl_05.jpg");
				}					
			}
			.carousel-control {
				display: none;
			}
		}
	}
	.priceList {
		display: inline-block;
		background-color: #94b54d;
		width: 100%;
		background-image: url("../assets/gfx/hfl_pricelist.jpg");
		background-position: center 97%;
		background-repeat: no-repeat;
		background-size: 300px;
		padding-bottom: 100px;
		// Small grid
		@media (min-width: @screen-sm-min) {
			background-position: center 90%;
			padding-bottom: 25vw;
			background-size: 70%;
		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			padding-bottom: 0;
			height: 50vw;
			width: 50%;
			background-position: center 97%;
		}
		// Large grid
		@media (min-width: @screen-lg-min) {
			background-size: 70%;
			background-position: center 90%;
		}	
		h2 {
			color: #ffffff;
			text-align: center;
			width: 100%;
			margin-top: 50px;
			background-image: url("../assets/gfx/hfl_prices_underline.png");
			background-repeat: no-repeat;
			background-position: bottom center;	
			padding-bottom: 25px;
			background-size: 300px;
			margin-bottom: 30px;		
			// Small grid
			@media (min-width: @screen-sm-min) {

			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				font-size: 2.2vw;
				margin-bottom: 2.5vw;
				background-size: 30vw;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}			
		}
		p {
			color: #ffffff;
			padding-left: 10%;
			span {
				font-family: 'Love Ya Like A Sister', cursive;
				font-size: 24px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					font-size: 1.8vw;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {

				}				
			}
			// Small grid
			@media (min-width: @screen-sm-min) {
				padding-left: 20%;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				font-size: 1.2vw;
				padding-left: 2vw;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {
				font-size: 1.1vw;
				padding-left: 4vw;
			}
			&.men {
				margin-top: 25px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					padding-left: 10%;
					margin-top: 0;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					padding-left: 1vw;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					padding-left: 1vw;
				}
			}
		}
	}
}
// 7. Section contact
#contact {
	padding-bottom: 50px;
	h2 {
		width: 100%;
		text-align: center;
		color: #94b54d;
		margin-top: 50px;
		background-image: url("../assets/gfx/hfl_qanda_underline.png");
		background-repeat: no-repeat;
		background-position: bottom center;	
		padding-bottom: 25px;
		background-size: 300px;
		margin-bottom: 40px;		
		// Small grid
		@media (min-width: @screen-sm-min) {

		}
		// Medium grid
		@media (min-width: @screen-md-min) {
			font-size: 3.2vw;
			background-size: 31vw;
			margin-bottom: 2vw;	
			padding-bottom: 2vw;			
		}
		// Large grid
		@media (min-width: @screen-lg-min) {

		}		
	}
	p {
		position: relative;
		color: #62645e;
		span.name {
			color: #94b54d;
			font-family: 'Love Ya Like A Sister', cursive;
			font-size: 18px;
		}	
		span.adress {
			padding-left: 30px;
			display: block;
			margin-left: 25px;
			// Small grid
			@media (min-width: @screen-sm-min) {
				margin-left: 0;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}	
			a {
				color: #62645e;
			}
			a.phonelink {
				text-decoration: none;
			}
			a.maillink {
				text-decoration: underline;
			}
		}
		img {
			position: absolute;
			width: 20px;
			&.icon_location {
				bottom: 78px;
				left: 25px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					left: 0px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					top: 165px;
					left: 0px;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					top: 135px;
					left: 0px;
				}					
			}
			&.icon_mail {
				bottom: -5px;
				left: 15px;
				width: 30px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					left: -10px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					top: 244px;
					left: -10px;
					width: 30px;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					top: 224px;
					left: -10px;
					width: 30px;
				}					
			}
			&.icon_phone {
				bottom: 27px;
				left: 214px;
				// Small grid
				@media (min-width: @screen-sm-min) {
					left: 188px;
				}
				// Medium grid
				@media (min-width: @screen-md-min) {
					top: 216px;
					left: 188px;
				}
				// Large grid
				@media (min-width: @screen-lg-min) {
					top: 196px;
					left: 188px;
				}					
			}			
		}
	}
	.powr-social-feed {
		margin-top: 50px;
	}
	iframe {
		width: 100%;
	}
}
// 8. section footer
#footer {
	background-color: #acc779;
	color: #ffffff;
	.impressum {
		position: relative;
		p.footer {
			position: relative;
			width: 100%;
			text-align: center;
			top: -23px;
			// Small grid
			@media (min-width: @screen-sm-min) {
				top: -23px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {
				top: -23px;
			}
			// Large grid
			@media (min-width: @screen-lg-min) {
				top: -23px;
			}		
			a {
				color: #ffffff;
			}
		}
	}
	#impressumContent {
		a {
			color: #ffffff;
			text-decoration: underline;
		}
	}
}
#impressum {
	padding-bottom: 80px;
	// Small grid
	@media (min-width: @screen-sm-min) {
		padding-bottom: 150px;
	}
	// Medium grid
	@media (min-width: @screen-md-min) {

	}
	// Large grid
	@media (min-width: @screen-lg-min) {

	}	
	.container {
		    padding-top: 50px;
			@media (min-width: @screen-sm-min) {
				padding-top: 100px;
			}
			// Medium grid
			@media (min-width: @screen-md-min) {

			}
			// Large grid
			@media (min-width: @screen-lg-min) {

			}				
	}
}
// Small grid
@media (min-width: @screen-sm-min) {

}
// Medium grid
@media (min-width: @screen-md-min) {

}
// Large grid
@media (min-width: @screen-lg-min) {

}
// Verry Large grid
@media (min-width: 1450px) {

}	